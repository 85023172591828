@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	.animation-normal {
		animation-direction: normal;
	}

	.animation-reverse {
		animation-direction: reverse; 
	}
}

html,body {
	width: 100%;
	height: 100%;
}

/* TODO: add "set fo-=r" to nvim autocommand */
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

#root {
	width: 100%;
	height: 100%;
}
